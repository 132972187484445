.bg-footer {
  background-color: #222222 !important;
}
ul {
  list-style-type: none !important;
}
.footer-border-color{
  border-color: #646464 !important;
}
.newsletter {
  background: url('./assets/Newsletter.png') !important;
  height: fit-content !important;
  background-size: cover !important;
}
.fs-6 {
  font-size: small !important;
}
.btn-newsletter{
  background-color: #F98106 !important;
  color: white;
}
.text-impact {
  color: #566A70 !important;
}
.card {
  border: none !important;
}
.img-service {
  width: 100px;
  height: 100px;
}
.services {
  background-color: #F7F9F5 !important;
}
.banner {
  background: url('./assets/hero.png');
  background-size: cover !important;
}
.text-yellow {
  color: #FFBC58;
}
.text-justify {
  text-align: justify !important;
}
.bg-contact-form {
  background-color: #F7F9F5 !important;  
}
.rounded {
  border-radius: 10% !important;
}
a {
  text-decoration: none !important;
}
.publication-container {
  position: relative;
}
.event-container {
  position: relative;
  text-align: center;
  color: white;
}
.event-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bottom: 0%; */
  transform: translate(-50%, -50%);
}
.event-action {
  position: absolute;
  bottom: 8px;
  right: 16px;
}
.message-container {
  position: relative;
  color: white;
}
.message-body {
  overflow: auto;
  display: flex;
  flex-grow: 1;
}
.publication {
  min-height: 240px;
  max-height: 240px;
  overflow: auto;
}
.title {
  overflow: auto;
}
.authors {
  display: flex;
  overflow: auto;
  flex-grow: 1;
}
@media screen and (min-width: 992px) {
  .event-pic {
    filter: brightness(50%) saturate(140%);
  }
}
.box-shadow:hover {
  cursor: pointer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.19) !important;
}